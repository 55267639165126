.showcase-wrapper {
    visibility: visible;
    animation: fadeIn 1.5s;
    position: absolute;
    transform-origin: 0px 0px;
    transition: background 0.5s linear 0s;
    background: rgb(223, 223, 223);
}

.showcase-item {
    display: inline-block;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
