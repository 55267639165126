.sticky-error {
    position: relative;
    margin: 0;
}

.sticky-error .text-danger {
    position: absolute;
    width: 100%;
    left: 0;
}

.filter-field {
    vertical-align: top;
    margin-bottom: 10px;
}

.case-det .email-det .ca-rgt {
  width: 100%;
  display: block;
  margin-top: 6px;
  text-align: left;
}

@media only screen and (max-width: 991px) {
  .case-det .email-det .ca-rgt {
    width: 49%;
    text-align: left;
    display: inline-block;
    margin-top: 0;
  }
}

@media only screen and (max-width: 700px) {
  .case-det .email-det .ca-rgt {
    width: 100%;
    display: block;
    margin-top: 4px;
    text-align: left;
  }
}

.man-btns.inline-buttons {
  display: inline-block;
  vertical-align: top;
  margin-top: 30px;
  margin-left: 20px;
}

@media only screen and (max-width: 1024px) {
  .man-btns.inline-buttons {
    display: block;
    margin-top: 15px;
    margin-left: 0;
  }
}

@media only screen and (max-width: 500px) {
  .msgs-bts, .msgs-bts-2 {
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }
  .man-btns {
    text-align: center;
  }

  .man-btns, .msgs-bts, .msgs-bts-2 a, button {
    float: none;
  }
}
