.multiline-text {
    white-space: pre-wrap;
}

.overflow-visible {
    overflow: visible;
}

.overflow-hidden {
    overflow: hidden;
}

.text-align-left {
    text-align: left;
}

.text-align-center {
    text-align: center;
}

.d-inline-block {
    display: inline-block;
}

.float-none {
    float: none;
}

.float-left {
    float: left;
}

.mang-table-wrap tbody tr.new-message {
  font-weight: bold;
}

.smk_accordion .accordion_in.new-message .text-accod h3,
.smk_accordion .accordion_in.new-message .text-accod {
  font-weight: bold;
}
