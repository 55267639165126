.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

body {
    overflow-x: hidden;
}

#wrap {
    position: relative;
    margin: 0px auto;
    width: 100%;
}

#showcase {
    width: 100%;
    height: 460px;
    visibility: hidden;
}

#showcase img {
    cursor: pointer;
}

#item-title {
    color: #F31414;
    font-size: 19px;
    letter-spacing: 0.13em;
    text-shadow: 1px 1px 6px #C72B2B;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 12px;
}

.nav {
    text-align: center;
}

.nav > button {
    width: 64px;
    height: 36px;
    color: #666;
    font: bold 16px arial;
    text-decoration: none;
    text-align: center;
    margin: 5px;
    text-shadow: 0px 1px 0px #f5f5f5;
    background: #f6f6f6;
    border: solid 2px rgba(0, 0, 0, 0.4);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.4);
    cursor: pointer;
}

.nav > button:active,
.nav > button.down {
    background: #dfdfdf;
    border: solid 2px rgba(0, 0, 0, 0.6);
    box-shadow: none;
}

#get {
    font-size: 20px;
    text-align: center;
}

#download {
    margin: 8px auto;
    margin-top: 12px;
    display: block;
}

#license {
    font-size: 18px;
    text-align: center;
    margin: 0;
}

#share {
    position: absolute;
    left: 4px;
    top: 478px;
}

.fb-like {
    vertical-align: top;
}

.twitter-share-button {
    width: 84px !important;
    margin-left: 8px;
}

#credits {
    color: #c9c9c9;
    padding: 10px;
    border: 2px #999 dashed;
    position: absolute;
    right: 0;
    bottom: 83px;
}

#credits ul {
    font-size: 14px;
    list-style-type: none;
    padding-left: 2px;
    margin: 2px 0;
}

#credits .author {
    color: white;
}

@media only screen and (max-width: 1280px) {
    #wrap {
        width: 980px;
        left: -490px;
        margin-left: 50%;
        position: relative;
    }
}