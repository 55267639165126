body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: 'Open Sans', sans-serif;
}

#container {
    position: relative;
    width: 100%;
    margin: 0 auto 25px;
    padding-bottom: 10px;
}

.containers2 {
    position: relative;
    width: 100%;
    margin: 0 auto 25px;
    padding-bottom: 10px;
}

.grid {
    width: 188px;
    background: #fff;
    margin: 8px;
    font-size: 12px;
    float: left;
    box-shadow: 0 1px 3px rgba(34, 25, 25, 0.4);
    -moz-box-shadow: 0 1px 3px rgba(34, 25, 25, 0.4);
    -webkit-box-shadow: 0 1px 3px rgba(34, 25, 25, 0.4);
    -webkit-transition: top 1s ease, left 1s ease;
    -moz-transition: top 1s ease, left 1s ease;
    -o-transition: top 1s ease, left 1s ease;
    -ms-transition: top 1s ease, left 1s ease;
}

.grid strong {
    border-bottom: 1px solid #ccc;
    margin: 10px 0;
    display: block;
    padding: 0 0 5px;
    font-size: 17px;
}

.grid .meta {
    text-align: right;
    color: #777;
    font-style: italic;
}

.grid .imgholder img {
    max-width: 100%;
    display: block;
}

.grid-detail {
    padding: 20px 15px;
    display: block;
}

.client-desc {
    display: block;
    width: 100%;
}

.client-icon {
    float: left;
}

.client-detail {
    float: left;
    margin-left: 11px;
    width: 78%;
}

.client-detail span {
    color: #444444;
    font-size: 15px;
    text-transform: uppercase;
}

.client-detail p {
    color: #018d6a;
    font-size: 17px;
    line-height: 21px;
}

.common-desc {
    width: 100%;
    margin-top: 15px;
}

.common-desc p {
    color: #444444;
    font-size: 13px;
    font-weight: 600;
}

.green-bot-line {
    border-bottom: 4px solid #01b286;
}

.grey-bot-line {
    border-bottom: 4px solid #919191;
}

.red-bot-line {
    border-bottom: 4px solid #d85454;
}

@media screen and (max-width: 1240px) {
    body {
        overflow: auto;
    }
}

@media screen and (max-width: 900px) {
    #backlinks {
        float: none;
        clear: both;
    }

    #backlinks a {
        display: inline-block;
        padding-right: 20px;
    }

    #wrapper {
        margin-top: 90px;
    }
}

@media only screen and (max-width: 800px) {
    .client-detail {
        width: 72%;
    }
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: 'Open Sans', sans-serif;
}

#container {
    position: relative;
    width: 100%;
    margin: 0 auto 25px;
    padding-bottom: 10px;
}

.containers2 {
    position: relative;
    width: 100%;
    margin: 0 auto 25px;
    padding-bottom: 10px;
}

.grid {
    width: 188px;
    min-height: 138px;
    background: #fff;
    margin: 8px;
    font-size: 12px;
    float: left;
    box-shadow: 0 1px 3px rgba(34, 25, 25, 0.4);
    -moz-box-shadow: 0 1px 3px rgba(34, 25, 25, 0.4);
    -webkit-box-shadow: 0 1px 3px rgba(34, 25, 25, 0.4);
    -webkit-transition: top 1s ease, left 1s ease;
    -moz-transition: top 1s ease, left 1s ease;
    -o-transition: top 1s ease, left 1s ease;
    -ms-transition: top 1s ease, left 1s ease;
}

.grid strong {
    border-bottom: 1px solid #ccc;
    margin: 10px 0;
    display: block;
    padding: 0 0 5px;
    font-size: 17px;
}

.grid .meta {
    text-align: right;
    color: #777;
    font-style: italic;
}

.grid .imgholder img {
    max-width: 100%;
    display: block;
}

.grid-detail {
    padding: 20px 15px;
    display: block;
}

.client-desc {
    display: block;
    width: 100%;
}

.client-icon {
    float: left;
}

.client-detail {
    float: left;
    margin-left: 11px;
    width: 78%;
}

.client-detail span {
    color: #444444;
    font-size: 15px;
    text-transform: uppercase;
}

.client-detail p {
    color: #018d6a;
    font-size: 17px;
    line-height: 21px;
}

.common-desc {
    width: 100%;
    margin-top: 15px;
}

.common-desc p {
    color: #444444;
    font-size: 13px;
    font-weight: 600;
}

.green-bot-line {
    border-bottom: 4px solid #01b286;
}

.grey-bot-line {
    border-bottom: 4px solid #919191;
}

.red-bot-line {
    border-bottom: 4px solid #d85454;
}

@media screen and (max-width: 1240px) {
    body {
        overflow: auto;
    }
}

@media screen and (max-width: 900px) {
    #backlinks {
        float: none;
        clear: both;
    }

    #backlinks a {
        display: inline-block;
        padding-right: 20px;
    }

    #wrapper {
        margin-top: 90px;
    }
}

@media only screen and (max-width: 800px) {
    .client-detail {
        width: 72%;
    }
}