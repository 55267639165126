html {
    font-size: 18px;
    /* Datepicker uses rem, force body to be larger */
}

.man-text .react-datepicker-popper {
    z-index: 11;
}

.man-text .react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker-wrapper input {
    background-image: url('../images/Zebra_DatePicker/calendar.png');
    background-position: 97% 50%;
    background-repeat: no-repeat;
}

.react-datepicker-wrapper input[disabled] {
    background-image: url('../images/Zebra_DatePicker/calendar-disabled.png');
}