.menu-left-bx.admin-style li {
    color: white;
    vertical-align: center;
    cursor: pointer;
    text-align: left;
}
.menu-left-bx.admin-style ul>li:last-child {
    border-bottom: none;
}
.menu-left-bx.admin-style li a {
    padding: 10px;
    color: white;
}
.menu-left-bx.admin-style li a.with-inner {
    padding-top: 0;
    padding-bottom: 0;
}
.menu-left-bx.admin-style li a.with-inner a {
    padding-left: 0;
}
.menu-left-bx.admin-style .collapse li {
    background: #0f2834;
}
.menu-left-bx.admin-style li.expand {
    padding-left: 25px;
    background-image: url(../../assets/images/styles.css/plus-icon.png);
    background-position: 5px 50%;
    background-repeat: no-repeat;
}