.header-cont {
    box-sizing: content-box;
    padding-top: env(safe-area-inset-top);
}

.footer.container {
    padding-bottom: env(safe-area-inset-bottom);
}

@media only screen and (max-width: 480px) {
    .Toastify__toast-container .Toastify__toast:first-child>.Toastify__close-button,
    .Toastify__toast-container .Toastify__toast:first-child>.Toastify__toast-body {
        padding-top: env(safe-area-inset-top);
    }
}