.custom-notification {
  background: rgba(236, 238, 239, 0.9) !important;
  color: #325367;
}

.custom-notification .custom-notification-progress {
  background-color: #337ab7;
}

.custom-notification .Toastify__close-button {
  color: #337ab7;
}
