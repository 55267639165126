.pop-up-background {
    background: rgba(40,40,40,.9);
    cursor: pointer;
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.pop-up-background-modal {
    cursor:default;
}

.pop-up {
    visibility:hidden;
    left: 50%;
    margin-left: -400px;
    max-width: 500px;
    position: absolute;
    top: 20px;
    width: 90%;
    z-index: 100;
}

.pop-up.small{
    margin-left: -200px;
    max-width: 400px;
}

.pop-up.medium{
    margin-left: -250px;
    max-width: 500px;
}

.pop-up.large{
    margin-left: -300px;
    max-width: 600px;
}
.pop-up-background {
    background: rgba(40, 40, 40, 0.9) none repeat scroll 0 0;
    cursor: pointer;
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}
.pop-up-content {
    background: #fff;
    -webkit-box-shadow:0 1px 10px 6px #111;
    -moz-box-shadow: 0 1px 10px 6px #111;
    box-shadow:      0 1px 10px 6px #111;
    min-height: 30px;
    padding: 24px;
}

.pop-up-content .close,
.pop-up-footer {
    text-align: right;
}

.pop-up-footer {
    padding-top: 24px;
}

.pop-up-body {
    font-size: 16px;
    color:#666;
}

.pop-up-display-content {
    display: none;
}

.pop-up .close {
    position:absolute;
    top:10px;
    right:10px;
    z-index: 99;
}


.pop-up-btn{display:none !important;}
.pop-up .close a{
    font-size: 18px;
    color: #d22;
    width: 24px;
    height: 24px;
    background: url(../../images/popupwindow.css/icon-cross.png) no-repeat 0 0;
    display: inline-block;
}
.pop-up .close a:hover{
    opacity: 0.6;
}

.pop-up-btn {
    padding: 8px 15px;
    display: inline-block;
    background: #3B4658;
    border-radius: 3px;
    margin-left: 10px;
    color: #fff;
    font-size: 15px;
    text-decoration: none;
}

.pop-up-btn:hover,
.pop-up-btn:focus {
    background: #6A798E;
}

.pop-up .screen-reader-text {
    position: absolute;
    top: -9999px;
    left: -9999px;
}

.ie6 .pop-up {
    border: 10px solid #CED4E2;
    width: 600px;
}

@media only screen and (max-width:760px) {
    .pop-up.large {
        left: 5%;
        margin: 0;
    }
}

@media only screen and (max-width:480px) {
    .pop-up.small,
    .pop-up.medium {
        left: 5%;
        margin: 0;
    }
    .pop-up-content {
        padding: 12px;
    }
    .pop-up-footer {
        padding-top: 12px;
    }
}
