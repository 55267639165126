.action-btn {
  background: #f46a0d;
  color: #fff;
  text-transform: uppercase;
  font-family: "robotobold";
  padding: 5px 20px;
  border-radius: 5px;
  margin-right: 20px;
  font-size: 14px;
  text-decoration: none !important;
  border: none;
}

.action-btn:focus,
.action-btn:hover {
  color: #fff;
  background: #325367;
}

.action-btn.right {
  float: right;
}

@media only screen and (max-width: 767px) {
  .action-btn {
    font-size: 13px;
    padding: 5px 14px;
  }
}

@media only screen and (max-width: 500px) {
  .action-btn {
    margin-bottom: 20px;
    display: inline-block;
    margin-left: 0px;
    padding: 7px 14px;
  }
  .action-btn.right {
    float: none;
  }
}

.ref-btn-m {
  background: #325367;
  color: #fff;
  text-transform: uppercase;
  padding: 5px 20px;
  border-radius: 5px;
  font-weight: 700;
  border: none;
  font-size: 14px;
  margin-right: 10px;
}

@media only screen and (max-width: 500px) {
  .ref-btn-ws {
    margin-bottom: 20px;
  }
}
