/* Used in scroll */
@import url(https://fonts.googleapis.com/css?family=Lobster+Two:700italic,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Oswald:300&display=swap);

/* Used for body */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,300,700italic,600italic,400italic,300italic&display=swap);

@font-face {
    font-family: 'robotolight';
    src: url('../fonts/Roboto-Light-webfont.eot');
    src: url('../fonts/Roboto-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Roboto-Light-webfont.woff') format('woff'),
         url('../fonts/Roboto-Light-webfont.ttf') format('truetype'),
        url('../fonts/Roboto-Light-webfont.svg#robotobold') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'robotobold'; src: url('../fonts/roboto-bold-webfont.eot');
    src: url('../fonts/roboto-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/roboto-bold-webfont.woff') format('woff'),
    url('../fonts/roboto-bold-webfont.ttf') format('truetype'),
    url('../fonts/roboto-bold-webfont.svg#robotobold') format('svg');
    font-weight: normal; font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'robotoregular';
    src: url('../fonts/roboto-regular-webfont.eot');
    src: url('../fonts/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/roboto-regular-webfont.woff') format('woff'),
    url('../fonts/roboto-regular-webfont.ttf') format('truetype'),
    url('../fonts/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal; font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'robotomedium';
    src: url('../fonts/Roboto-Medium-webfont.eot');
    src: url('../fonts/Roboto-Medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto-Medium-webfont.woff') format('woff'),
    url('../fonts/Roboto-Medium-webfont.ttf') format('truetype'),
    url('../fonts/Roboto-Medium-webfont.svg#robotomedium') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'roboto_condensedbold';
    src: url('../fonts/RobotoCondensed-Bold-webfont.eot');
    src: url('../fonts/RobotoCondensed-Bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/RobotoCondensed-Bold-webfont.woff') format('woff'),
    url('../fonts/RobotoCondensed-Bold-webfont.ttf') format('truetype'),
    url('../fonts/RobotoCondensed-Bold-webfont.svg#roboto_condensedbold') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'robotolight';
    src: url('../fonts/Roboto-Light-webfont.eot');
    src: url('../fonts/Roboto-Light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto-Light-webfont.woff') format('woff'),
    url('../fonts/Roboto-Light-webfont.ttf') format('truetype'),
    url('../fonts/Roboto-Light-webfont.svg#robotolight') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
