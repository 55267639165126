@font-face {
  font-family: 'aim-icons';
  src:
    url('fonts/aim-icons.ttf?i28p1o') format('truetype'),
    url('fonts/aim-icons.woff?i28p1o') format('woff'),
    url('fonts/aim-icons.svg?i28p1o#aim-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="cs-"], [class*=" cs-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'aim-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cs-note_add:before {
  content: "\e905";
}
.cs-event_note:before {
  content: "\e906";
}
.cs-delete_forever:before {
  content: "\e900";
}
.cs-trash:before {
  content: "\e901";
}
.cs-pencil-square:before {
  content: "\e902";
}
.cs-edit:before {
  content: "\e903";
}
.cs-pencil-square-o:before {
  content: "\e903";
}
.cs-pencil:before {
  content: "\e904";
}
.cs-eye:before {
  content: "\e9ce";
}
