.smk_accordion {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 20px;
}

/**
 * --------------------------------------------------------------
 * Section
 * --------------------------------------------------------------
 */
.smk_accordion .accordion_in {
    border: 1px solid #ccc;
    position: relative;
    z-index: 10;
    margin-top: 11px;
    overflow: hidden;
}

/**
 * --------------------------------------------------------------
 * Head
 * --------------------------------------------------------------
 */
.smk_accordion .accordion_in .acc_head {
    position: relative;
    background: #ebebeb;
    padding: 10px;
    font-size: 14px;
    display: block;
    cursor: pointer;
}

.smk_accordion .accordion_in .acc_head .acc_icon_collapse,
.smk_accordion .accordion_in .acc_head .acc_icon_expand {
    display: block;
    width: 22px;
    height: 22px;
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -9px;
    background: url(../../images/styles.css/plus-icon.png) center 0;
}

/**
 * --------------------------------------------------------------
 * Content
 * --------------------------------------------------------------
 */
.smk_accordion .accordion_in .acc_content {
    background: #fff;
    color: #7B7E85;
    padding: 0;
    border-top: 1px solid #c7c7c7;
}

.smk_accordion .accordion_in .acc_content h1:first-of-type,
.smk_accordion .accordion_in .acc_content h2:first-of-type,
.smk_accordion .accordion_in .acc_content h3:first-of-type,
.smk_accordion .accordion_in .acc_content h4:first-of-type,
.smk_accordion .accordion_in .acc_content h5:first-of-type,
.smk_accordion .accordion_in .acc_content h6:first-of-type {
    margin-top: 5px;
}

/**
 * --------------------------------------------------------------
 * General
 * --------------------------------------------------------------
 */
.smk_accordion .accordion_in:first-of-type,
.smk_accordion .accordion_in:first-of-type .acc_head {
    border-radius: 3px 3px 0 0;
}

.smk_accordion .accordion_in:last-of-type,
.smk_accordion .accordion_in:last-of-type .acc_content {
    border-radius: 0 0 3px 3px;
}

.smk_accordion .accordion_in .acc_head.acc_active {
    background: #fff;
}

.smk_accordion .accordion_in .acc_head .acc_icon_collapse,
.smk_accordion .accordion_in .acc_head.acc_active .acc_icon_expand {
    background: url(../../images/styles.css/minus-icon.png) center 0px;
}

.smk_accordion.acc_with_icon .accordion_in .acc_head {
    padding-left: 47px;
}

.smk_accordion.acc_with_icon .accordion_in .acc_content {
    padding-left: 30px;
}

.smk_accordion.acc_with_icon .accordion_in .acc_content.collapsed {
    overflow: hidden;
    transition: max-height 0.5s ease;
}

.smk_accordion.acc_with_icon .accordion_in .acc_content.collapsed {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    border-top: none;
}

.smk_accordion.acc_with_icon .accordion_in .acc_content.expanded {
    max-height: 1000px;
}


.head-acc {
    display: block;
    width: 100%;
    font-size: 15px;
    font-weight: 600;
    color: #445a67;
    font-family: 'Open Sans', sans-serif;
}
