header, section, footer, hgroup {
    display: block;
}

.clearfix {
    clear: both;
}

#container {
    position: relative;
    width: 1100px;
    margin: 0 auto 25px;
    padding-bottom: 10px;
}

.grid {
    width: 250px !important;
    min-height: 138px;
    padding: 21px 24px !important;
    background: #fff;
    margin: 8px;
    font-size: 12px;
    float: left;
    box-shadow: 0 1px 3px rgba(34, 25, 25, 0.4);
    -moz-box-shadow: 0 1px 3px rgba(34, 25, 25, 0.4);
    -webkit-box-shadow: 0 1px 3px rgba(34, 25, 25, 0.4);
    -webkit-transition: top 1s ease, left 1s ease;
    -moz-transition: top 1s ease, left 1s ease;
    -o-transition: top 1s ease, left 1s ease;
    -ms-transition: top 1s ease, left 1s ease;
}

.grid strong {
    border-bottom: 1px solid #ccc;
    margin: 10px 0;
    display: block;
    padding: 0 0 5px;
    font-size: 17px;
}

.grid .meta {
    text-align: right;
    color: #777;
    font-style: italic;
}

.grid .imgholder img {
    max-width: 100%;
    background: #ccc;
    display: block;
}

.success-mg {
    background: #ebf7ff;
    text-align: center;
    width: 100%;
    border: 1px solid #a5cbe3;
    padding: 20px 0px;
    margin-bottom: 20px;
}

.success-mg img {
    margin-bottom: 15px;
}

.success-mg p {
    color: #3a454d;
    font-size: 15px;
    font-family: 'robotomedium';
}

.btn-log {
    background: #f46a0d;
    border-radius: 20px;
    color: #fff;
    padding: 3px 20px;
    font-family: "robotobold";
    font-size: 16px;
    margin: 15px 0px 0px
}

.reset-portion {
    margin: 0px auto;
    float: none;
}

.reset-part {
    background: #fff;
    padding: 24px;
    overflow: hidden;
    box-shadow: 0px 0px 3px 3px #dadada;
}

.r-block {
    width: 100%;
    display: block;
    margin-bottom: 16px;
}

.r-block label {
    font-size: 15px;
    font-family: 'robotoregular';
    color: #555;
    width: 100%;
    font-weight: normal;
}

.r-block .re-text {
    width: 99%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.re-btn {
    border-top: 1px solid #ddd;
    padding: 20px 0px 10px;
    margin-top: 20px;
    width: 100%;
}

.s-btn {
    background: #f46a0d;
    border-radius: 5px;
    color: #fff;
    padding: 5px 20px;
    font-family: "robotobold";
    font-size: 14px;
    text-transform: uppercase;
    float: right;
    border: none;
}

.c-btn {
    background: #35779f;
    border-radius: 5px;
    color: #fff;
    padding: 5px 20px;
    font-family: "robotobold";
    font-size: 14px;
    text-transform: uppercase;
    float: right;
    /* margin-left: 15px; */
    border: none;
}

.number-bx li {
    padding: 5px 11px;
    color: #fff;
    text-decoration: none;
    display: inline-block;
    outline: none;
}

@media screen and (max-width: 1240px) {
    body {
        overflow: auto;
    }
}

@media screen and (max-width: 1024px) {
    .grid {
        width: 270px !important
    }
}

@media screen and (max-width: 980px) {
    .grid {
        width: 200px !important
    }
}

@media screen and (max-width: 900px) {
    #backlinks {
        float: none;
        clear: both;
    }

    #backlinks a {
        display: inline-block;
        padding-right: 20px;
    }

    #wrapper {
        margin-top: 90px;
    }

    .grid {
        width: 200px !important
    }
}

@media screen and (max-width: 640px) {
    .grid {
        width: 44% !important
    }
}

@media screen and (max-width: 470px) {
    .grid {
        width: 96% !important
    }
}
