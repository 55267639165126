.generic-input {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 5px 10px;
}

.generic-input.small {
    width: 100px;
}

.align-right-on-big {
    text-align: right;
}

input[readonly].readonly-pointer {
    cursor: pointer !important;
}

@media only screen and (max-width: 990px) {
    .align-right-on-big {
        text-align: left;
    }
}
.flex-man {
    display: flex;
    align-content: flex-start;

}
.flex-man .man-text {
    overflow: inherit;
    margin-bottom: 10px;
}
@media only screen and (max-width: 500px) {
    .flex-man {
        flex-direction: column;
    }
}