/** That batch of styles seems to be included on every legacy page */
@import "./legacy/bootstrap.min.v2.css";
@import "./legacy/style.css";
@import "./legacy/style-blocksit.v2.css";
@import "./legacy/main.v2.css";
@import "./legacy/style-scroll.v2.css";
@import "./legacy/pintrest-style.v2.css";
@import "./legacy/popupwindow.v1.css";
@import "./legacy/default.v2.css";
@import "./legacy/smk-accordion.css";

/** legacy style fixes */
.show {
    opacity: 1;
}

.nav-tabs {
    background: none;
}

.nav-tabs > .nav-item {
    margin-bottom: -1px;
    float: left;
    margin-right: 20px;
    padding-right: 12px;
    padding-left: 12px;
    color: #ff7200;
    font-weight: 600;
    font-size: 16px;
    outline: none;
    text-decoration: none;
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 18px;
    border-radius: 4px 4px 0 0;
    border: 1px solid transparent;
}

.nav-tabs > .nav-item.active {
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent !important;
}

.nav-tabs > .nav-item:hover {
    border-color: #eeeeee #eeeeee #dddddd;
}

.account-block {
    width: 50%;
    float: right;
}


.account-block .input-bl {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 7px 10px;
    width: 100%;
}

.radio {
    margin-bottom: 10px;
    margin-top: 0;
}

.radio label {
    padding-left: 21px;
    margin: 0;
}

.radio-inline {
    padding-left: 21px;
}

.radio-inline label {
    margin: 0;
}


@media only screen and (max-width: 400px) {
    .nav-tabs > .nav-item {
        font-size: 12px;
        margin-right: 2px;
        padding-left: 3px;
        padding-right: 3px;
    }

    .account-block {
        float: none;
        width: 100%;
    }
}
