.modal-backdrop {
    background: rgba(40,40,40,.9);
    cursor: pointer;
}

.modal-backdrop.show.fade {
    opacity: 1;
}

.modal-content {
    border: none;
    border-radius: 0;
    background: #fff;
    box-shadow: 0 1px 10px 6px #111;
    min-height: 30px;
}

.modal-title {
    font-size: 20px;
    font-family: 'robotoregular', sans-serif;
    color: #ff7200;
}

.modal-content button.close {
    font-size: 40px;
    margin-top: -34px;
}

.modal-content > .unsus-msg {
    margin-left: 15px;
    margin-right: 15px;
}

.modal-header {
    border-bottom: none;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
}

.modal-body {
    padding: 24px;
    border-radius: 5px;
}

.modal-footer {
    border-top: none;
}

.modal.show .modal-dialog {
    transform: none;
    min-height: calc(100% - 3.5rem);
    display: flex;
    align-items: center;
}
.modal.show .modal-content {
    width: 100%;
}

.modal .close a {
    font-size: 18px;
    color: #d22;
    width: 24px;
    height: 24px;
    background: url(../images/popupwindow.css/icon-cross.png) no-repeat 0 0;
    display: inline-block;
}

.modal .close a:hover {
    opacity: 0.6;
}

.modal .screen-reader-text {
    position: absolute;
    top: -9999px;
    left: -9999px;
}
