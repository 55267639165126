.upload-list-item {
    display: flex;
    margin-bottom: 10px;
}
.upload-list-item  .upload-button-wrap {
    width: calc(100% - 100px);
}
.upload-list-item .upload-button-wrap2 {
  width: calc(55% + 86px);
}
.upload-list-item  .remove-file {
    padding-top: 2px;
}

.uploaded-files-wrapper {
  display: flex;
  flex-direction: column;
}
